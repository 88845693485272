
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  defineAsyncComponent,
  watch,
  ref,
  onUnmounted,
  onMounted,
  nextTick
} from "vue";

import NavBar from "@/components/molecules/nav-bar.vue";
import { useRoute, useRouter } from "vue-router";
import { useMeta, useActiveMeta } from "vue-meta";
import { envShortName } from "@/helpers/env";
import useSystem from "@/compositions/use-system";
import useEventsBus from "@/event-bus";
import VModal from "./components/atoms/v-modal.vue";
import dayjs from "dayjs";
import { FunToWaitApi } from "@vacancorp/funtowait.adapter.api.vacanservice.com";
const { bus } = useEventsBus();

type StateData = {
  isMenuShown: boolean;
  windowWidth: number;
  isAnnouncePopupShown: boolean;
  latestPopupAnnounce?: FunToWaitApi.ResponseGetSystemAnnounce;
};

export default defineComponent({
  name: "AppView",
  components: {
    VModal,
    NavBar,
    MenuView: defineAsyncComponent(() => import("@/views/menu-view.vue")),
    TooLarge: defineAsyncComponent(() => import("@/components/organisms/too-large.vue"))
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const systemUsecase = useSystem();
    // global template
    useMeta({
      titleTemplate:
        process.env.VUE_APP_MODE === "production"
          ? `%s | Fun to Wait`
          : `[${envShortName(process.env.VUE_APP_MODE ?? "")}] ` + `%s | Fun to Wait`
    });
    // set title
    const metadata = useActiveMeta();
    watch(metadata, (newValue) => {
      document.title = newValue.titleTemplate.replace("%s", newValue.title ?? "");
    });
    // data
    const states: StateData = reactive({
      isMenuShown: false,
      windowWidth: 0,
      isAnnouncePopupShown: false,
      latestPopupAnnounce: undefined
    });
    // for update child UI
    const navKey = ref(0);
    /** computes */
    const computedProps = {
      showRightColNavBar: computed((): boolean => {
        const regex = /\/(login|signup|forgot-password)(\/.*)?$/;
        return !regex.test(route.path) && route.path !== "/";
      }),

      notToShowNavBar: computed((): boolean => {
        const regex = /^\/(en)?(\/)?(bye|welcome\/.*)?$/;
        return regex.test(route.path);
      }),
      // set view by innerWidth
      isWinWidthLarge: computed((): boolean => states.windowWidth > 500)
    };

    onMounted(async () => {
      // service closed
      try {
        const result = await systemUsecase.readSystemSetting();
        if (result.isServiceClosed) {
          router.push("/bye");
          return;
        }

        const announces = await systemUsecase.readSystemAnnounces();
        const popupAnnounces = announces.filter((ann) => ann.isPopup);
        // only popup the lastest one
        const popupAnnouncesDesc = popupAnnounces
          .slice()
          .sort((acc1, acc2) => dayjs(acc2.updatedUnixtime).unix() - dayjs(acc1.updatedUnixtime).unix());
        states.latestPopupAnnounce = popupAnnouncesDesc[0];
        methods.checkToShowPopup();
      } catch {
        console.error(`get system setting failed`);
      }

      nextTick(() => {
        states.windowWidth = window.innerWidth;
        window.addEventListener("resize", methods.onResize);
      });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", methods.onResize);
    });

    watch(
      () => route.fullPath,
      () => {
        window.scrollTo(0, 0);
        methods.checkToShowPopup();
        navKey.value += 1;
      }
    );
    // re-render UI via event bus
    watch(
      () => bus.value.get("rerender-nav-points"),
      () => {
        navKey.value++;
      }
    );

    const methods = {
      onResize() {
        states.windowWidth = window.innerWidth;
      },

      isAnnouncePopupSet(): boolean {
        return systemUsecase.popupAnnouncesStatus() !== undefined;
      },
      checkToShowPopup() {
        const splitRoutes = states.latestPopupAnnounce?.popupRoutes?.split(",");
        if (splitRoutes?.length === 0) return (states.isAnnouncePopupShown = false);
        const matched = splitRoutes?.some((r: string) => {
          if (r === "/" || r === "/en" || r === "/en/") return r === route.path;
          else return route.path.includes(r);
        });
        if (matched) states.isAnnouncePopupShown = true;
        else states.isAnnouncePopupShown = false;
      },
      confirmAnnouncePopup() {
        states.isAnnouncePopupShown = false;
        systemUsecase.setPopupAnnouncesUnixtime(dayjs().unix());
      }
    };

    return {
      ...toRefs(states),
      ...computedProps,
      navKey,
      ...methods
    };
  }
});
