/**
 * @remarks Login functions
 * */
import {
    ResponsePostLogin,
    RequestPostLogin,
    RequestPostForgotPassword,
    RequestPostResetPassword,
    ResponseGetUser
} from "../../@types/fun-to-wait";
import $http from "./http";
import { AxiosResponse, AxiosError } from "axios";
import { Auth } from "./auth";
import { getRefreshToken } from "@/compositions/auth";
const auth = new Auth();

export async function login({ email, password }: RequestPostLogin): Promise<ResponsePostLogin> {
    return await $http
        .post(`/v1/user/login`, { email, password })
        .then((response: AxiosResponse) => {
            if (!auth.isResponsePostLogin(response.data)) {
                throw new Error();
            }
            return response.data as ResponsePostLogin;
        })
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function logout(): Promise<boolean> {
    return await $http
        .post(`/v1/user/logout`)
        .then((response: AxiosResponse) => {
            return response.status === 200 || response.status === 401;
        })
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function renewSession(): Promise<ResponsePostLogin> {
    return await $http
        .post(`/v1/user/session`, {
            refreshToken: getRefreshToken()
        })
        .then((response: AxiosResponse) => {
            if (!auth.isResponsePostLogin(response.data)) {
                throw new Error();
            }
            return response.data as ResponsePostLogin;
        })
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function signup({ email }: { email: string }): Promise<boolean> {
    return await $http
        .post(`/v1/user/signup`, { email })
        .then((response: AxiosResponse) => {
            if (response.status >= 400) throw new Error();
            return response.status === 200 || response.status === 204;
        })
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function forgotPassword({ email }: RequestPostForgotPassword): Promise<boolean> {
    return await $http
        .post(`/v1/user/forgot-password`, { email })
        .then((response: AxiosResponse) => {
            if (response.status >= 400) throw new Error();
            return response.status === 200 || response.status === 204;
        })
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function resetPassword({
    email,
    newPassword,
    confirmationCode
}: RequestPostResetPassword): Promise<boolean> {
    return await $http
        .post(`/v1/user/reset-password`, { email, newPassword, confirmationCode })
        .then((response: AxiosResponse) => {
            if (response.status >= 400) throw new Error();
            return response.status === 200 || response.status === 204;
        })
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function fetchUser(): Promise<ResponseGetUser> {
    return await $http
        .get(`/v1/user`)
        .then((response: AxiosResponse) => {
            if (response.status >= 400) throw new Error();
            return response.data;
        })
        .catch((error: AxiosError) => Promise.reject(error.response));
}
