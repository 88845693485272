import { getCookie, setCookie } from "../helpers/io/cookie";
import { ResponsePostLogin } from "@/../@types/fun-to-wait";

export const getAccessToken = (): string => getCookie("vacan/funtowait/accessToken") ?? "";
export const getRefreshToken = (): string => getCookie("vacan/funtowait/refreshToken") ?? "";
export const authorizationItem = (): string => `Bearer ${getAccessToken() || ""}`;

export const haveSession = (): boolean => {
    const token: string | undefined = getAccessToken();
    return token !== undefined && token.length > 0;
};

export const setToken = (tokens: ResponsePostLogin) => {
    setCookie("vacan/funtowait/accessToken", tokens.accessToken, 1);
    setCookie("vacan/funtowait/refreshToken", tokens.refreshToken, 30);
};

export const cleanToken = () => {
    setCookie("vacan/funtowait/accessToken", "", -1);
    setCookie("vacan/funtowait/refreshToken", "", -1);
};

export const isLoggedIn = () => getAccessToken().length > 0;
