import { IndexedDb } from "../datastore/total-points.datastore";

async function getDB() {
    const db = new IndexedDb();
    await db.createObjectStore();
    return db;
}

export async function getValue(): Promise<number> {
    const db = await getDB();
    return await db.getValue();
}

export async function putValue(points: number) {
    const db = await getDB();
    return await db.putValue(points);
}
