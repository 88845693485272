import { getSystemAnnounces, getSystemSetting } from "@/apis/service.api";
import { getCookie, setCookie } from "../helpers/io/cookie";
import type { ResponseGetSystemAnnounce, ResponseGetSystemSetting } from "@/../@types/fun-to-wait";

export default function useSystem() {
    const readSystemAnnounces = async (): Promise<ResponseGetSystemAnnounce[]> => await getSystemAnnounces();
    const readSystemSetting = async (): Promise<ResponseGetSystemSetting> => await getSystemSetting();

    const popupAnnouncesStatus = (): number | undefined => {
        const cookie = getCookie("vacan/funtowait/popup-announces");
        if (!cookie) return undefined;
        return parseInt(cookie);
    };

    const setPopupAnnouncesUnixtime = (accessUnixtime: number) =>
        setCookie("vacan/funtowait/popup-announces", accessUnixtime.toString(), 999);

    return { readSystemAnnounces, readSystemSetting, popupAnnouncesStatus, setPopupAnnouncesUnixtime };
}
