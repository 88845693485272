import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5405c55a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-bar" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "right-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_NavPoints = _resolveComponent("NavPoints")!
  const _component_VButton = _resolveComponent("VButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      _createVNode(_component_RouterLink, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/images/logo.svg'),
            alt: "funtowait logo",
            width: "88"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      })
    ]),
    (_ctx.isRightColShown)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          (_openBlock(), _createBlock(_component_NavPoints, {
            key: _ctx.navKey,
            "nav-key": _ctx.navKey
          }, null, 8, ["nav-key"])),
          _createVNode(_component_VButton, {
            class: "menu-button",
            type: "button",
            "ui-type": "transparent",
            "icon-name": "menu",
            "icon-size": 24,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menu-clicked', true)))
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}