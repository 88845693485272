import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cab8693"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: ['v-button', _ctx.status, _ctx.uiType]
  }, _ctx.$attrs, { "aria-label": _ctx.title }), [
    (_ctx.status === 'loading')
      ? (_openBlock(), _createBlock(_component_spinner, {
          key: 0,
          width: 20,
          height: 20
        }))
      : (_ctx.convertedIconName)
        ? (_openBlock(), _createBlock(_component_icon, {
            key: 1,
            name: _ctx.convertedIconName,
            class: "icon",
            size: _ctx.iconSize
          }, null, 8, ["name", "size"]))
        : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: _normalizeClass(['title', { 'sr-only': _ctx.status !== 'normal' }])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true)
  ], 16, _hoisted_1))
}