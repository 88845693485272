import { createI18n } from "vue-i18n";
// by ISO-3166
import enUS from "./en/en-US.json";
import jaJP from "./ja/ja-JP.json";

const i18n = createI18n({
    locale: "ja-JP",
    fallbackLocale: "ja-JP",
    legacy: false, // false for compositionAPI
    globalInjection: true, // use $t in template
    messages: {
        "ja-JP": jaJP,
        "en-US": enUS
    }
});

export default i18n;
