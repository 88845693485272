import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c44ed5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "lead-text" }
const _hoisted_3 = { class: "basic-text" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VButton = _resolveComponent("VButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "modal-content",
      style: _normalizeStyle({
        '--minContentWidth': _ctx.minContentWidth,
        '--maxContentWidth': _ctx.maxContentWidth,
        '--minContentHeight': _ctx.minContentHeight,
        '--maxContentHeight': _ctx.maxContentHeight
      })
    }, [
      _createElementVNode("header", null, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.modalTitle), 1),
        _createVNode(_component_VButton, {
          type: "button",
          class: "close-btn",
          "ui-type": "transparent",
          "icon-name": "close",
          "icon-size": 24,
          "sr-title": "close modal",
          onClick: _ctx.hide
        }, null, 8, ["onClick"])
      ]),
      _createElementVNode("article", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.pattern === 'btn2')
        ? (_openBlock(), _createElementBlock("footer", _hoisted_4, [
            _createVNode(_component_VButton, {
              type: "button",
              class: "btn2-left",
              "ui-type": _ctx.btn2Left?.uiType ? _ctx.btn2Left?.uiType : 'secondary',
              title: _ctx.btn2Left?.title,
              disabled: _ctx.btn2Left?.disabled,
              onClick: _ctx.btn2Left?.action
            }, null, 8, ["ui-type", "title", "disabled", "onClick"]),
            _createVNode(_component_VButton, {
              type: "button",
              class: "btn2-right",
              "ui-type": _ctx.btn2Right?.uiType ? _ctx.btn2Right?.uiType : 'danger',
              title: _ctx.btn2Right?.title,
              disabled: _ctx.btn2Right?.disabled,
              status: _ctx.btn2Right?.status,
              onClick: _ctx.btn2Right?.action
            }, null, 8, ["ui-type", "title", "disabled", "status", "onClick"])
          ]))
        : (_openBlock(), _createElementBlock("footer", _hoisted_5, [
            _createVNode(_component_VButton, {
              type: "button",
              class: "btn1",
              "ui-type": _ctx.btnUiType,
              title: _ctx.btnTitle,
              onClick: _ctx.btnClicked
            }, null, 8, ["ui-type", "title", "onClick"])
          ]))
    ], 4),
    _createElementVNode("div", {
      class: "window-overlay",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
    })
  ]))
}