import { IDBPDatabase, openDB } from "idb";
import { CHECK_IN_OUT_TABLE, OWNED_TICKETS_TABLE, POINTS_TABLE } from "@/domains";
const DB_NAME = "funtowait-reward.api";

export class InitDb {
    private database: string;
    // eslint-disable-next-line
    public db: any;

    constructor() {
        if (!("indexedDB" in window)) {
            console.error(`this browser is not supporting indexDB`);
            throw new Error();
        }
        this.database = DB_NAME;
    }

    public async createObjectStore() {
        try {
            this.db = await openDB(this.database, 3, {
                upgrade(db: IDBPDatabase) {
                    db.createObjectStore(CHECK_IN_OUT_TABLE, { autoIncrement: true, keyPath: "placeIdHash" });

                    db.createObjectStore(OWNED_TICKETS_TABLE, { autoIncrement: true, keyPath: "userRewardItemIdHash" });

                    db.createObjectStore(POINTS_TABLE);
                }
            });
            // init total-points table
            const tx = this.db.transaction(POINTS_TABLE, "readwrite");
            const store = tx.objectStore(POINTS_TABLE);
            const val = (await store.get("points")) || 0;
            await store.put(val + 0, "points");
            // await store.put(0, "points");
        } catch (error) {
            console.error(`create object store failed`);
            return false;
        }
    }
}
