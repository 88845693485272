import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7aad8397"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-points" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon),
    (_openBlock(), _createElementBlock("span", {
      class: "point-number sofia-medium",
      key: _ctx.navKey
    }, _toDisplayString(_ctx.userPoints), 1))
  ]))
}