import * as TotalPointsRepo from "@/indexed-db/repository/total-points.repository";

// saved points
export async function getAllPoints(): Promise<number> {
    return await TotalPointsRepo.getValue();
}

export async function minusPoints(points: number) {
    const savedPoints = await getAllPoints();
    const result = savedPoints - points < 0 ? 0 : savedPoints - points;
    TotalPointsRepo.updateInsertValue(result);
}

export async function resetPoints() {
    TotalPointsRepo.updateInsertValue(0);
}

export async function getValue(): Promise<number> {
    return await TotalPointsRepo.getValue();
}

export async function updateInsertValue(points: number) {
    return await TotalPointsRepo.updateInsertValue(points);
}
