
import { defineComponent } from "vue";
import VButton from "@/components/atoms/v-button.vue";
import NavPoints from "@/components/atoms/points/nav-points.vue";

export default defineComponent({
  name: "component-nav-points",
  components: { VButton, NavPoints },
  props: {
    isRightColShown: {
      type: Boolean,
      default: true
    },
    navKey: {
      type: Number,
      default: 0
    }
  }
});
