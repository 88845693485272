
import { defineComponent, reactive, onMounted, toRefs } from "vue";
import Icon from "@/components/atoms/points/point-icon.vue";
import { getUserPoints } from "@/apis/rewards.api";
import { isLoggedIn } from "@/compositions/auth";
import * as TotalPointsUsecase from "@/compositions/local-apis/total-points.usecase";

export default defineComponent({
  name: "component-nav-points",
  components: { Icon },
  props: {
    navKey: Number
  },
  setup() {
    // data
    const states = reactive({
      userPoints: 0
    });

    onMounted(async () => {
      await methods.getPoints();
    });

    const methods = {
      getPoints: async () => {
        if (!isLoggedIn()) {
          states.userPoints = await TotalPointsUsecase.getAllPoints();
          return;
        }
        try {
          const result = await getUserPoints();
          states.userPoints = result.totalPoint;
        } catch {
          console.error(`fet user point failed`);
          states.userPoints = 0;
        }
      }
    };

    return {
      ...toRefs(states),
      ...methods
    };
  }
});
