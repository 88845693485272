/** @remarks i18n and language convertors */

// Set <html lang='xx'>
export function setHtmlLang(locale: string) {
    const documentRoot = document.getElementsByTagName("html")[0];
    if (documentRoot) {
        documentRoot.lang = locale;
    }
}

/**
 * Convertor for url path and locale
 * url: /en, /tw
 * locale: ISO-3166
 */
export function toLongLang(short: string): "en-US" | "ja-JP" {
    switch (short) {
        case "en":
            return "en-US";
        default:
            return "ja-JP";
    }
}

export function toShortLang(long: string): "en" | "tw" | "cn" | "ko" | undefined {
    switch (long) {
        case "en-US":
            return "en";
        case "zh-TW":
            return "tw";
        case "zh-CN":
            return "cn";
        case "ko-KR":
            return "ko";
        default:
            return undefined;
    }
}
