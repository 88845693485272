import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Teleport as _Teleport, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a78d5c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-text content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuView = _resolveComponent("MenuView")!
  const _component_TooLarge = _resolveComponent("TooLarge")!
  const _component_VModal = _resolveComponent("VModal")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isMenuShown)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "transparent-bg",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isMenuShown = false))
        }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (_ctx.isMenuShown)
          ? (_openBlock(), _createBlock(_component_MenuView, {
              key: 0,
              onCloseClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isMenuShown = false)),
              onGoCheckinClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isMenuShown = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.isWinWidthLarge)
      ? (_openBlock(), _createBlock(_component_TooLarge, { key: 1 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
            (_ctx.isAnnouncePopupShown && !_ctx.isAnnouncePopupSet())
              ? (_openBlock(), _createBlock(_component_VModal, {
                  key: 0,
                  class: "announce-modal",
                  "btn-action": _ctx.confirmAnnouncePopup,
                  "modal-title": _ctx.latestPopupAnnounce?.title,
                  "btn-title": _ctx.$t('common.button.popupConfirmed'),
                  "btn-ui-type": "primary",
                  onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isAnnouncePopupShown = false))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.latestPopupAnnounce?.content), 1)
                  ]),
                  _: 1
                }, 8, ["btn-action", "modal-title", "btn-title"]))
              : _createCommentVNode("", true)
          ])),
          (!_ctx.notToShowNavBar)
            ? (_openBlock(), _createBlock(_component_NavBar, {
                key: 0,
                "nav-key": _ctx.navKey,
                "is-right-col-shown": _ctx.showRightColNavBar,
                onMenuClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isMenuShown = true))
              }, null, 8, ["nav-key", "is-right-col-shown"]))
            : _createCommentVNode("", true),
          _createVNode(_component_RouterView, { class: "app-content" })
        ], 64))
  ], 64))
}