import { createApp } from "vue";
import App from "./App.vue";
import { createMetaManager } from "vue-meta";
import VueGtag from "vue-gtag-next";
import i18n from "./i18n";

const app = createApp(App);
const metaManager = createMetaManager();
app.use(metaManager);
app.use(VueGtag, {
    property: {
        id:
            process.env.VUE_APP_MODE === "development" || process.env.VUE_APP_MODE === "staging"
                ? "G-7648CC03SD"
                : "G-BTHH0B45FR"
    }
});

// vue-router should be put after vue-gtag-next
import router from "./router";
app.use(router).use(i18n).mount("#app");
