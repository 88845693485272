/**
 * @remarks Rewards and points functions
 * */
import { ResponseGetSystemSetting, ResponseGetSystemAnnounce } from "../../@types/fun-to-wait";
import $http from "./http";
import { AxiosResponse, AxiosError } from "axios";

export async function getSystemSetting(): Promise<ResponseGetSystemSetting> {
    return await $http
        .get(`/v1/system-setting`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function getSystemAnnounces(): Promise<ResponseGetSystemAnnounce[]> {
    return await $http
        .get(`/v1/system-announces`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}
