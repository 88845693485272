import { isLoggedIn } from "@/compositions/auth";
import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardNext } from "vue-router";
import { trackRouter } from "vue-gtag-next";
// i18n
import i18n from "@/i18n";
import { setHtmlLang, toLongLang } from "../helpers/i18n";
import { parameterizedURLQuery } from "@/helpers/url";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/:lang?",
        name: "home",
        component: () => import("../pages/index.vue")
    },
    {
        path: "/:lang?/error",
        name: "error-page",
        component: () => import("../pages/errors/400.vue")
    },
    {
        path: "/:lang?/error/used",
        name: "error-used-page",
        component: () => import("../pages/errors/404.vue")
    },
    {
        path: "/:lang?/login",
        name: "login",
        component: () => import("../pages/login/index.vue")
    },
    {
        path: "/:lang?/forgot-password",
        name: "forgot-password",
        component: () => import("../pages/forgot-password/index.vue")
    },
    {
        path: "/:lang?/forgot-password/reset",
        name: "forgot-password-reset",
        component: () => import("../pages/forgot-password/reset.vue")
    },
    {
        path: "/:lang?/signup",
        name: "signup",
        component: () => import("../pages/signup/index.vue")
    },
    {
        path: "/:lang?/signup/reset",
        name: "signup-reset",
        component: () => import("../pages/signup/reset.vue")
    },
    {
        path: "/:lang?/signup/profile",
        name: "signup-profile",
        component: () => import("../pages/signup/profile.vue")
    },
    {
        path: "/:lang?/welcome/:placeIdHash",
        name: "welcome",
        component: () => import("../pages/welcome/_placeIdHash/index.vue")
    },
    {
        path: "/:lang?/bye",
        name: "service-end",
        component: () => import("../pages/service-end.vue")
    },
    {
        path: "/:lang?/places",
        name: "place-list",
        component: () => import("../pages/places/index.vue")
    },
    {
        path: "/:lang?/places/:placeIdHash",
        name: "place-detail",
        component: () => import("../pages/places/_placeIdHash/index.vue")
    },
    {
        path: "/:lang?/places/:placeIdHash/standby",
        name: "place-standby",
        component: () => import("../pages/places/_placeIdHash/standby/index.vue")
    },
    {
        path: "/:lang?/places/:placeIdHash/checkin",
        name: "place-checkin",
        component: () => import("../pages/places/_placeIdHash/checkin/index.vue")
    },
    {
        path: "/:lang?/places/:placeIdHash/checkout",
        name: "place-checkout",
        component: () => import("../pages/places/_placeIdHash/checkout/index.vue")
    },
    {
        path: "/:lang?/tutorial",
        name: "tutorial",
        component: () => import("../pages/tutorial.vue")
    },
    // login auth is required below
    {
        path: "/:lang?/account",
        name: "account",
        meta: { requireAuth: true },
        component: () => import("../pages/account.vue")
    },
    {
        path: "/:lang?/tickets/exchangeable/:rewardItemIdHash",
        name: "tickets-exchangeable-detail",
        component: () => import("../pages/tickets/exchangeable/_rewardItemIdHash/index.vue")
    },
    {
        path: "/:lang?/tickets/owned/:userRewardItemIdHash",
        name: "tickets-owned-detail",
        component: () => import("../pages/tickets/owned/_userRewardItemIdHash/index.vue")
    },
    {
        path: "/:lang?/tickets",
        name: "tickets",
        component: () => import("../pages/tickets/index.vue"),
        children: [
            {
                path: "exchangeable",
                name: "exchangeable",
                component: () => import("../pages/tickets/exchangeable/index.vue")
            },
            {
                path: "owned",
                name: "owned",
                component: () => import("../pages/tickets/owned/index.vue")
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

const setupLocale = (lang?: string) => {
    if (lang !== undefined) {
        i18n.global.locale.value = toLongLang(lang);
    } else {
        i18n.global.locale.value = `ja-JP`;
    }
    setHtmlLang(lang ?? `ja`); // need shorten form
};

router.beforeEach((to, from, next: NavigationGuardNext) => {
    // route with language prefix
    const fromLang = from.params.lang as string | undefined;
    const toLang = to.params.lang as string | undefined;
    const query = to.query as { [Key: string]: string };
    // prefix lang on path and redirect
    if (toLang) setupLocale(toLang);
    if (fromLang && !toLang) {
        return next(`/${from.params.lang}${to.path}?${parameterizedURLQuery(query)}`);
    }

    const isLoginNeeded = to.matched.some((record) => record.meta.requireAuth);
    if (isLoginNeeded && !isLoggedIn()) {
        return next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
        return next();
    }
});

// gtag
trackRouter(router);

export default router;
