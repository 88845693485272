import { POINTS_TABLE } from "@/domains";
import { InitDb } from "./db";

export class IndexedDb extends InitDb {
    public async getValue(): Promise<number> {
        const tx = this.db.transaction(POINTS_TABLE, "readonly");
        const store = tx.objectStore(POINTS_TABLE);
        const result: number = (await store.get("points")) || 0;
        return result;
    }

    public async putValue(points: number) {
        const tx = this.db.transaction(POINTS_TABLE, "readwrite");
        const store = tx.objectStore(POINTS_TABLE);
        return await store.put(points, "points");
    }
}
