
import { defineComponent } from "vue";

export default defineComponent({
  name: "component-spinner",
  props: {
    width: {
      type: Number,
      default: 70,
      required: false
    },
    height: {
      type: Number,
      default: 70,
      required: false
    }
  }
});
