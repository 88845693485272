import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ResponsePostLogin } from "../../@types/fun-to-wait";
import { renewSession } from "./login.api";
import { authorizationItem, haveSession, setToken } from "@/compositions/auth";
import i18n from "@/i18n";

const http: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_ADAPTER_API,
    timeout: 10 * 1000
});

http.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (haveSession()) {
            config.headers.common["Authorization"] = authorizationItem();
        }
        // i18n lang param
        config.params = {
            ...config.params,
            lang: i18n.global.locale.value
        };

        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error.response);
    }
);

// Check accesstoken validity if not try to refresh tokens
http.interceptors.response.use(
    async (response: AxiosResponse): Promise<AxiosResponse> => {
        return response;
    },
    async (error: AxiosError) => {
        const originalRequest: AxiosRequestConfig = error.config;
        // FIX: take off when Cognito issue gets fixed
        // Cognito needs token even logout, that is weird
        if (originalRequest.url?.includes("/logout") || originalRequest.url?.includes("/reset-password")) {
            return Promise.resolve(error.response);
        } else if (error.response !== undefined && error.response.status === 401) {
            if (!haveSession()) {
                // router.push("/login");
                return Promise.reject(error);
            }

            const loginResult: ResponsePostLogin | undefined = await renewSession().catch(() => undefined);
            if (loginResult === undefined) {
                // router.push("/login");
                return Promise.reject(error);
            }
            setToken({ accessToken: loginResult.accessToken, refreshToken: loginResult.refreshToken });
            originalRequest.headers["Authorization"] = `Bearer ${loginResult.accessToken}`;
            return http.request(originalRequest);
        }

        return Promise.reject(error);
    }
);

export default http;
