import type { ResponseGetRewardItem } from "@/../@types/fun-to-wait";

// max points
export const MAX_POINTS = 80; // minutes

// Points saving
export const POINTS_TABLE = "total_points";

export const CHECK_IN_OUT_TABLE = "check_in_out";
export interface LocalCheckInOut {
    placeIdHash: string;
    name: string;
    fromUnixtime?: number;
    toUnixtime?: number;
    points: number;
    status: "checkin" | "checkout";
}

// Points exchanged
export const OWNED_TICKETS_TABLE = "owned_tickets";
export type LocalOwnedTickets = ResponseGetRewardItem & {
    exchangeUnixtime: number;
    usedUnixtime?: number;
    fromUnixtime?: number; // for owned-detail-item-card on reload
};
