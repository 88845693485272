import { ResponsePostLogin } from "../../@types/fun-to-wait";
import { IAuth, AccessToken } from "../../@types/auth";

export class Auth implements IAuth {
    accessToken?: AccessToken;
    isResponsePostLogin(tokens: unknown): tokens is ResponsePostLogin {
        return (
            "accessToken" in (tokens as ResponsePostLogin) &&
            typeof (tokens as ResponsePostLogin).accessToken === "string"
        );
    }
    parseJwt(token: string) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }
}
