/**
 * @remarks Rewards and points functions
 * */
import dayjs from "dayjs";
import {
    ResponseGetRewardItem,
    ResponseGetUserRewardItem,
    ResponseGetUserTotalPoint,
    ResponseGetRewardPlace,
    RequestPostUserPoint,
    ResponsePostExchange
} from "../../@types/fun-to-wait";
import $http from "./http";
import { AxiosResponse, AxiosError } from "axios";

export async function getUserPoints(): Promise<ResponseGetUserTotalPoint> {
    return await $http
        .get(`/v1/user/total-point`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}

/** 交換できるチケット */
export async function getExchangeableTickets(): Promise<ResponseGetRewardItem[]> {
    return await $http
        .get(`/v1/reward/reward-items`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}
export async function getExchangeableTicket(rewardItemIdHash: string): Promise<ResponseGetRewardItem> {
    return await $http
        .get(`/v1/reward/reward-items/${rewardItemIdHash}`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}
export async function postUserExchangeableTicket(
    rewardItemIdHash: string,
    exchangeUnixtime = dayjs().unix()
): Promise<ResponsePostExchange> {
    return await $http
        .post(`/v1/reward/reward-items/${rewardItemIdHash}/exchange`, { exchangeUnixtime })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}

// TODO: 交換後にすぐに使う機能
// その時点userRewardItemIdHash持っていない
// export async function getUserRewardItemIdHash(rewardItemIdHash: string): Promise<string> {
//     return await $http
//         .get(`/v1/reward/reward-items/${rewardItemIdHash}/user`)
//         .then((response: AxiosResponse) => response.data)
//         .catch((error: AxiosError) => Promise.reject(error.response));
// }

/** 待つチケット */
export async function getUserOwnedTickets(): Promise<ResponseGetUserRewardItem> {
    return await $http
        .get(`/v1/reward/user/reward-items`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function getUserOwnedTicket(userRewardItemIdHash: string): Promise<ResponseGetUserRewardItem[]> {
    return await $http
        .get(`/v1/reward/user/reward-items/${userRewardItemIdHash}`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function putUserOwnedTicket(
    userRewardItemIdHash: string,
    params: {
        usedUnixtime: number;
    }
): Promise<ResponseGetUserRewardItem> {
    return await $http
        .put(`/v1/reward/user/user-reward-items/${userRewardItemIdHash}/use`, { ...params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}

/** 施設一覧 */
export async function getPlaceList(): Promise<ResponseGetRewardPlace> {
    return await $http
        .get(`/v1/reward/places`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}

export async function getPlace(placeIdHash: string): Promise<ResponseGetRewardPlace> {
    return await $http
        .get(`/v1/reward/places/${placeIdHash}`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}

/** ギフトポイント */
export async function postUserPointsOnce(): Promise<ResponseGetRewardPlace> {
    const userPoint: RequestPostUserPoint = {
        unixtime: dayjs().unix()
    };
    return await $http
        .post(`/v1/reward/user-point`, userPoint)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response));
}
