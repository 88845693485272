
import { defineComponent, computed } from "vue";
import Icon from "@/components/atoms/material-icon.vue";
import Spinner from "@/components/atoms/spinner.vue";
export type ButtonStatus = "normal" | "loading" | "success" | "error";

export default defineComponent({
  name: "component-v-button",
  components: {
    Icon,
    Spinner
  },
  inheritAttrs: false,
  props: {
    uiType: {
      type: String,
      validator: (value: string) => {
        return ["primary", "secondary", "tertiary", "text", "danger", "transparent"].includes(value);
      },
      required: true
    },
    title: { type: String, required: false },
    iconName: { type: String, default: undefined, required: false },
    iconSize: { type: Number, default: 24 },
    status: {
      type: String,
      validator: (value: string) => {
        return ["normal", "loading", "success", "error"].includes(value);
      },
      default: "normal",
      required: false
    }
  },

  setup(props) {
    const computedProps = {
      convertedIconName: computed(() => {
        switch (props.status) {
          case "loading":
            return "";
          case "success":
            return "done";
          case "error":
            return "error";
          default:
            return props.iconName;
        }
      })
    };

    return {
      ...computedProps
    };
  }
});
