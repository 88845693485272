import { ref } from "vue";
const bus = ref(new Map());

export default function useEventsBus() {
    // eslint-disable-next-line
    function emitBus(event: any, ...args: any[]) {
        bus.value.set(event, args);
    }

    return { emitBus, bus };
}
