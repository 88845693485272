
export type VSelectOption = { value: string; label: string; color?: string; disabled?: boolean };
import { PropType, defineComponent } from "vue";
import VButton from "@/components/atoms/v-button.vue";

export default defineComponent({
  name: "component-dialog",
  components: { VButton },
  props: {
    modalTitle: String,
    btnTitle: {
      type: String,
      default: "OK"
    },
    btnAction: Function,
    btnUiType: {
      type: String,
      default: "primary"
    },
    minContentWidth: { type: String, default: "332px" },
    maxContentWidth: { type: String, default: "800px" },
    minContentHeight: { type: String, default: "165px" },
    maxContentHeight: { type: String, default: "90vh" },
    pattern: {
      type: String,
      required: false,
      validator: (value: string) => ["btn2"].includes(value)
    },
    btn2Left: {
      type: Object as PropType<{
        title: string;
        action: () => void;
        disabled?: boolean;
        uiType?: string;
      }>,
      required: false
    },
    btn2Right: {
      type: Object as PropType<{
        title: string;
        action: () => void;
        disabled?: boolean;
        uiType?: string;
        status?: string;
      }>,
      required: false
    }
  },

  setup(props, { emit }) {
    const methods = {
      hide() {
        emit("hide");
      },
      btnClicked() {
        if (!props.btnAction) methods.hide();
        else {
          props.btnAction();
        }
      }
    };

    return {
      ...methods
    };
  }
});
