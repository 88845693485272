export function parameterizedURLQuery(obj: { [Key: string]: string } | { [Key: string]: [string] }): string {
    const merged = Object.keys(obj)
        .map(function (k) {
            const value: string | [string] | undefined = obj[k];
            if (value === undefined) {
                delete obj[k];
            } else if (k === "genreCategoryList" && typeof value === "object" && value.length > 0) {
                return encodeURIComponent(k) + "=" + encodeURIComponent(value.join(","));
            } else if (value.length === 0) {
                delete obj[k];
            } else if (typeof value === "string") {
                return encodeURIComponent(k) + "=" + encodeURIComponent(value);
            }
        })
        .filter((value) => value !== undefined);
    return merged.join("&");
}
