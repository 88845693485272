
// https://material.io/icons/
import { defineComponent } from "vue";
export default defineComponent({
  name: "component-icon",
  props: {
    name: { type: String, required: true },
    size: {
      type: Number,
      required: false,
      default: 24
    },
    rotate: {
      type: Number,
      required: false,
      default: 0,
      validator: (value: number) => [0, 90, 180, 270].includes(value)
    }
  }
});
